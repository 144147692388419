<template>
  <pl-table
    :data="data"
    border
    stripe
    :height="tableHeight"
    :row-height="rowHeight"
    use-virtual
    ref="table"
    :row-class-name="tableRowClassName"
    @row-dblclick="goBill"
  >
    <el-table-column label="单据编号" prop="billCode" show-overflow-tooltip></el-table-column>
    <el-table-column label="单据日期" width="100">
      <template slot-scope="scope">{{scope.row.billDate|datetime}}</template>
    </el-table-column>
    <el-table-column label="客户" prop="clientName" show-overflow-tooltip></el-table-column>
    <el-table-column label="结算金额" show-overflow-tooltip>
      <template slot-scope="scope">{{scope.row.amount|thousands}}</template>
    </el-table-column>
    <el-table-column label="累计金额" show-overflow-tooltip>
      <template slot-scope="scope">{{scope.row.endAmount|thousands}}</template>
    </el-table-column>
    <el-table-column label="摘要" prop="remark" show-overflow-tooltip></el-table-column>
  </pl-table>
</template>

<script>
import setName from "@/common/setName";
import tableHeight from "@/common/tableHeightMixins";
import toExcel from "@/common/toExcel";
export default {
  mixins: [tableHeight],
  props: ["filter"],
  data() {
    return {
      data: []
    };
  },
  methods: {
    tableRowClassName({ row }) {
      if (!row.billId) {
        return "bold-row";
      } else {
        return "";
      }
    },
    getData() {
      let params = { ...this.filter };
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$get("ProjectReceiptPayment/Receipt", params)
        .then(r => {
          let endAmount = 0;
          r.forEach(item => {
            endAmount = this.amountFormat(
              endAmount + item.amount + item.endAmount
            );
            item.endAmount = endAmount;
            if (item.clientId) {
              setName("Client", item, "clientId", "clientName");
            }
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    goBill(row) {
      if (row.billId && this.hasPermission("Payment")) {
        this.$router.push({
          name: "PaymentItem",
          query: { id: row.billId }
        });
      }
    },
    excel(projectName) {
      let list = [];
      this.data.forEach(item => {
        list.push({
          billCode: item.billCode,
          billDate: item.billDate
            ? this.$moment(item.billDate).format("YYYY-MM-DD")
            : "",
          clientName: item.clientName,
          amount: this.amountFormat(item.amount),
          endAmount: this.amountFormat(item.endAmount),
          remark: item.remark
        });
      });
      let header = [
        "单据编号",
        "单据日期",
        "客户",
        "结算金额",
        "累计金额",
        "摘要"
      ];
      let column = [
        "billCode",
        "billDate",
        "clientName",
        "amount",
        "endAmount",
        "remark"
      ];

      toExcel(header, column, list, `${projectName}-结算`);
    }
  }
};
</script>

<style>
</style>