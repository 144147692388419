<template>
  <pl-table
    :data="data"
    border
    stripe
    :height="tableHeight"
    :row-height="rowHeight"
    use-virtual
    ref="table"
    :row-class-name="tableRowClassName"
    @row-dblclick="goBill"
  >
    <el-table-column label="单据编号" prop="billCode" show-overflow-tooltip></el-table-column>
    <el-table-column label="单据日期" prop="billDate" width="100">
      <template slot-scope="scope">{{scope.row.billDate|datetime}}</template>
    </el-table-column>
    <el-table-column label="供应商" prop="supplierName" show-overflow-tooltip></el-table-column>
    <el-table-column label="物资类别" prop="typeName" show-overflow-tooltip></el-table-column>
    <el-table-column label="物资编号" prop="goodsCode" show-overflow-tooltip></el-table-column>
    <el-table-column label="物资名称" prop="goodsName" show-overflow-tooltip></el-table-column>
    <el-table-column label="规格" prop="goodsSpec" show-overflow-tooltip></el-table-column>
    <el-table-column label="单位" prop="goodsUnit" show-overflow-tooltip></el-table-column>
    <el-table-column label="数量" show-overflow-tooltip>
      <template slot-scope="scope">{{scope.row.quantity|thousands}}</template>
    </el-table-column>
    <el-table-column label="付款金额" show-overflow-tooltip>
      <template slot-scope="scope">{{scope.row.payAmount|thousands(2)}}</template>
    </el-table-column>
    <el-table-column label="分摊金额" show-overflow-tooltip>
      <template slot-scope="scope">{{scope.row.apportionAmount|thousands}}</template>
    </el-table-column>
    <el-table-column label="累计金额" show-overflow-tooltip>
      <template slot-scope="scope">{{scope.row.endAmount|thousands}}</template>
    </el-table-column>
    <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
  </pl-table>
</template>

<script>
import setName from "@/common/setName";
import tableHeight from "@/common/tableHeightMixins";
import toExcel from "@/common/toExcel";
export default {
  mixins: [tableHeight],
  props: ["filter"],
  data() {
    return {
      data: []
    };
  },
  methods: {
    tableRowClassName({ row }) {
      if (!row.billId) {
        return "bold-row";
      } else {
        return "";
      }
    },
    getData() {
      let params = { ...this.filter };
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$get("ProjectReceiptPayment/GoodsByDetail", params)
        .then(r => {
          let endAmount = 0;
          r.forEach(item => {
            endAmount = this.amountFormat(
              endAmount + item.payAmount + item.endAmount + item.apportionAmount
            );
            item.endAmount = endAmount;
            if (item.supplierId) {
              setName("Supplier", item, "supplierId", "supplierName");
            }
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    goBill(row) {
      if (row.billId && this.hasPermission("Purchase")) {
        this.$router.push({
          name: "PurchaseItem",
          query: { id: row.billId }
        });
      }
    },
    excel(projectName) {
      let list = [];
      this.data.forEach(item => {
        list.push({
          billCode: item.billCode,
          billDate: item.billDate
            ? this.$moment(item.billDate).format("YYYY-MM-DD")
            : "",
          supplierName: item.supplierName,
          typeName: item.typeName,
          goodsCode: item.goodsCode,
          goodsName: item.goodsName,
          goodsSpec: item.goodsSpec,
          goodsUnit: item.goodsUnit,
          quantity: item.quantity,
          payAmount: this.amountFormat(item.payAmount),
          apportionAmount: this.amountFormat(item.apportionAmount),
          endAmount: this.amountFormat(item.endAmount),
          remark: item.remark
        });
      });
      let header = [
        "单据编号",
        "单据日期",
        "供应商",
        "物资类别",
        "物资编号",
        "物资名称",
        "规格",
        "单位",
        "数量",
        "付款金额",
        "分摊金额",
        "累计金额",
        "备注"
      ];
      let column = [
        "billCode",
        "billDate",
        "supplierName",
        "typeName",
        "goodsCode",
        "goodsName",
        "goodsSpec",
        "goodsUnit",
        "quantity",
        "payAmount",
        "apportionAmount",
        "endAmount",
        "remark"
      ];

      toExcel(header, column, list, `${projectName}-物资`);
    }
  }
};
</script>

<style>
</style>